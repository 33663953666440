<template>
  <button
    :class="`${classes} flex select-none items-center justify-center text-[0.938rem] font-medium outline-none transition-all`"
  >
    <icon v-if="icon" :icon="icon" class="mr-2 h-[1rem] w-[1rem]"></icon>
    <slot></slot>
    <i v-if="loading" class="pi pi-spin pi-spinner ml-5" />
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String || null,
      default: null,
    },
    design: {
      type: String,
      default: "1",
    },
    size: {
      type: String,
      default: "md",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      const styleClasses = {
        1: "bg-white hover:bg-primary-lightest hover:text-primary-light text-[#4C6287]  text-primary active:bg-primary-lighter active:text-primary-light focus:ring-2 ring-primary-light",
        2: "bg-transparent border border-primary-lightest  text-primary-lightest  hover:bg-white hover:text-primary active:bg-primary-lighter active:text-primary-light focus:border-transparent focus:ring-2 ring-[#506184]",
        3: "bg-ternary hover:bg-ternary-light  border-primary-lightest  text-white  hover:text-white active:bg-ternary-lightest active:text-ternary-lighter focus:ring-1  focus:text-ternary focus:bg-transparent focus:ring-ternary",
        4: "bg-secondary hover:bg-secondary-light  border-primary-lightest  text-white  hover:text-white active:bg-secondary-lightest active:text-secondary-lighter focus:ring-1  focus:text-secondary focus:bg-transparent focus:ring-secondary",
        5: "bg-red-500 hover:bg-red-600 border-red-500  text-white  hover:text-white active:bg-red-500 active:text-red-600 focus:ring-1  focus:text-red-500 focus:bg-transparent focus:ring-red-500",
      };

      const sizeClasses = {
        sm: "leading-none py-3 px-6 rounded-full",
        md: "leading-none py-4 px-6 rounded-full",
        lg: "leading-none py-5 px-6 rounded-full",
      };

      const disabledClasses =
        "opacity-50 cursor-not-allowed pointer-events-none";

      return `${styleClasses[this.design]} ${sizeClasses[this.size]} ${
        this.disabled ? disabledClasses : ""
      }`;
    },
  },
};
</script>
