<template>
  <svg class="inline-block fill-current stroke-current stroke-0">
    <use :xlink:href="`#icon-${icon}`"></use>
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "loupe",
    },
  },
};
</script>
